import React from "react";
import Layout from "../components/layout";
import OurworkFooter from "../components/OurworkFooter";
import { languageType } from "../utils/JSONData";
import "../Assets/styles/ourwork.scss";
import { Link } from "gatsby";
import { Row, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { graphql } from 'gatsby';
import 'react-tabs/style/react-tabs.css';
import Fade from 'react-reveal/Fade';
import Scrolltop from "../components/Scrolltotop/scrolltop";

//strapi URL
import { imagePathURL } from "../utils/JSONData";
import Footer from "../components/Footer/Footer";

const OurWork = ({ data }) => {
  const titleObj = data.allOurWorkTitles.nodes[0].data.attributes;
  const ourWorkProduct = data.allOurWorkProducts.nodes[0].data;
  //   if (!data) {
  //   return (
  //     <Layout>
  //       <div className="titleDescription">
  //         <div
  //           className="titleDescription__first-section"
  //           style={{
  //             height: "90vh",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <div class="spinner-grow text-secondary" role="status">
  //             <span class="sr-only">Loading...</span>
  //           </div>
  //         </div>
  //       </div>
  //     </Layout>
  //   )
  // }
  const mapViewDesign = (item) => {
    return (
      <Row className="">
        <Col xs={12} md={6}>
          <Fade left>


            <div className="ourwork_proj_leftview">
              <img src={imagePathURL + item?.attributes.bgimage.data.attributes.url} alt="background" />
              <div
                className="ourwork_proj_description"
                style={{ color: item?.attributes.color }}
              >
                {item?.attributes.description}
              </div>
              <div className="ourwork_title">{item?.attributes.title}</div>
              <div className="d-flex">
                {item?.attributes.language.replace(/\[|\]/g, '').split(',').map((l, i) => {
                  return (
                    <div key={i}>
                      <button
                        className="ourwork_button"
                        style={{
                          border: `2px solid ${item?.attributes.color}`,
                          color: item?.attributes.color,
                        }}
                      >
                        {l}
                      </button>
                    </div>
                  );
                })}
              </div>
              <Link to={'/' + item.attributes.pageLink} className="text-decoration-none">
                <div
                  className="ourwork_view_project"
                  style={{ color: item?.attributes.color, backgroundImage: `url(${imagePathURL + item.attributes.backgroundImage.data.attributes.url})` }}
                >
                  VIEW PROJECT
                </div>
              </Link>
            </div>
          </Fade>
        </Col>
        <Col xs={12} md={6}>
          <Fade right>
            <img className="ourwork_proj_image" alt="projects" src={imagePathURL + item?.attributes.image.data.attributes.url} />
          </Fade>
        </Col>
      </Row>
    )
  };

  return (
    <Layout>
      <div className="ourwork_content">
        <Row>
          <Col sm={12} md={6} className="m-auto">
            <div>
              <div className="ourwork_title">{titleObj.title}</div>
              <div className="ourwork_details">
                {titleObj.description}
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} className="text-center">
            <img src={imagePathURL + titleObj.image.data.attributes.url} alt="signman" className="ourwork_first-img" />
          </Col>
        </Row>
      </div>
      <div>
        <div className="ourwork_work_title">Our Work</div>
        <div className="ourwork_details" style={{ textAlign: "center" }}>
          Browse our service range in various verticals and industries.
        </div>

        <Tabs>
          <TabList>
            <div className="ourwork_language_content">
              {languageType.map((item, i) => {
                return (
                  <Tab key={i}>
                    <div className="ourwork_language_name">
                      {item?.title}
                    </div>
                  </Tab>
                );
              })}
            </div>
          </TabList>

          <TabPanel>
            {ourWorkProduct.map((item, i) => {
              return (
                <div key={i} className="ourwork_proj_allview">
                  {mapViewDesign(item)}
                </div>
              )
            })}
          </TabPanel>
          <TabPanel>
            {ourWorkProduct.filter(fil => fil.attributes.item === 'Mobile Apps').map((item, i) => {
              return (
                <div key={i} className="ourwork_proj_allview">
                  {mapViewDesign(item)}
                </div>
              )
            })}
          </TabPanel>
          <TabPanel>
            {ourWorkProduct.filter(fil => fil.attributes.item === 'Web').map((item, i) => {
              return (
                <div key={i} className="ourwork_proj_allview">
                  {mapViewDesign(item)}
                </div>
              )
            })}
          </TabPanel>
        </Tabs>
      </div>
      {/* <OurworkFooter /> */}
      <Footer />
      <Scrolltop />
    </Layout>
  );
};

export const query = graphql`
  {
    allOurWorkProducts {
      nodes {
        data {
          attributes {
            item
            title
            color
            description
            pageLink
            language
            backgroundImage {
              data {
                attributes {
                  url
                }
              }
            }
            bgimage {
              data {
                attributes {
                  url
                }
              }
            }
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allOurWorkTitles {
      nodes {
        data {
          attributes {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OurWork;